<script lang="ts" setup>
import { GetHomeQuery } from "~~/types/graphql";
import getHome from "@/graphql/home.query.graphql";

const { locale } = useI18n();
const { data } = await useAsyncQuery<GetHomeQuery>(getHome, {
  locale: locale.value,
});

const config = useRuntimeConfig();

const localization = useLocalization();
localization.value.de.pathMatch = [""];
localization.value.en.pathMatch = [""];

setSeo(data.value.home?.data?.attributes?.seo, data.value.home?.data?.attributes?.localizations, "website");
</script>
<template>
  <main>
    <block-header-stage
      :header="data?.home?.data?.attributes?.header"
      :fallback="data?.home?.data?.attributes?.Titel"
    />
    <BlockRenderer
      v-for="(block, index) in data?.home?.data?.attributes?.Components"
      v-bind="block"
      :key="`${block?.__typename}-${index}`"
    />
    <block-footer
      :headline="data?.home?.data?.attributes?.footer?.Titel"
      :link="data?.home?.data?.attributes?.footer?.link"
    />
  </main>
</template>
